import React, { useEffect, useState, useRef } from 'react';
//import logo from './Resources/meic-logo-color.png';
import logo from './Resources/meicNuevo.png';
import logo_blanco from './Resources/meic-logo-blanco.png';
import logo_ice from './Resources/kolbi.png';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form';
import { getProvincias, getCantones, getProductos, getDistritos, getEstudioActivo, getPrecios, getPresentaciones, getMsjQuinPdcs } from './Requests';
import {
	Button,
} from './style';
import { faCircleInfo, faGlobe, faQuestion } from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css"; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function App() {

		
	const [blockedDocument, setBlockedDocument] = useState(false);
	const [respuesta, setRespuesta] = useState(false);

	const captcha = useRef(null);
	const [captchaValido, cambiarCaptchaValido] = useState(null);

	const [provincia, setProvincias] = useState([]);
	const [canton, setCantones] = useState([]);
	const [distrito, setDistritos] = useState([]);
	const [producto, setProductos] = useState([]);
	const [presentacion, setPresentacion] = useState([]);
	const [precio, setPrecio] = useState([]);
	const [estudio, setEstudio] = useState([]);
	const [pcsMsg, setPcsMsg] = useState([]);
	const [msgInfo, setMsgInfo] = useState("");
	const [visibleDialog, setVisibleDialog] = useState(false);

	const [errorRequest, setErrorRequest] = useState(false);
	const { register, watch } = useForm();
	const [isValidProv, setIsValidProv] = useState(true); //muestra alerta validacion
	const [isValidProd, setIsValidProd] = useState(true); //muestra alerta validacion
	const [errorValidMsgProv, setErrorValidMsgProv] = useState(""); 
	const [errorValidMsgProd, setErrorValidMsgProd] = useState(""); 

	let watchProvincia = watch('idProvincia');
	let watchCanton = watch('idCanton');
	let watchDistrito = watch('idDistrito');
	let watchProducto = watch('idProducto');
	let watchPresentacion = watch('idPresentacion');

	const headerTemplate = (data) => {
		if(data.producto == "Grupo de los 15"){
			return (
				<React.Fragment >
					<b><span className="image-text"> {data.producto}</span></b>    
					<a style={{marginLeft: 10+'px'}} onClick={infoGrupo15} title="Información">
						<FontAwesomeIcon  icon={faCircleInfo}/>
					</a>
				</React.Fragment>

				   
				);
		}else{
			return (
				<React.Fragment >
					<b><span className="image-text"> {data.producto} {data.tamano} {data.unidad}</span></b>                
				</React.Fragment>

				   
				);
		}
       
    }

	const infoGrupo15 = () =>{
		setVisibleDialog(true);
	}

	const onHideDialog = (name) => {
        setVisibleDialog(false);
    }

	const plantillaPrecio = (dato) => {
        return (dato.precio).toLocaleString('es-CR', { style: 'currency', currency: 'CRC' });
    }

	const validarCampos = () => {
		var todoBien = true;
		if (watchProvincia === ""){
			setIsValidProv(false);
			setErrorValidMsgProv("Seleccione una provincia");
			todoBien = false;
		}
		if (watchProducto === ""){
			setIsValidProd(false);
			setErrorValidMsgProd("Seleccione un producto");
			todoBien = false;
		}
		return todoBien;
	}

	async function process() {
		
		if(captcha.current.getValue()){
			
			if (validarCampos()){
				setBlockedDocument(true);
				var idEstudioActivo = "1";

				estudio.map((value) => (
					idEstudioActivo = value.elEstudio.idEstudio
				)) 
				
				const response = await getPrecios(watchProvincia, watchCanton, watchDistrito,
					watchProducto, watchPresentacion, idEstudioActivo, captcha.current.getValue());
				response.errors ? setErrorRequest(true) : setPrecio(response);
		
				cambiarCaptchaValido(true);
				setBlockedDocument(false);
				window.grecaptcha.reset();
			}else{
				return;
			}
		}else{
			cambiarCaptchaValido(false);
		}
	}

	useEffect(() => {
		async function fetchInitialData() {
			
			setRespuesta(false);

			const response = await getProvincias();
			if(typeof response != 'string'){
				response.errors ? setErrorRequest(true) : setProvincias(response);
			}
			

			const responsePcts = await getProductos();
			if(typeof responsePcts != 'string'){
				responsePcts.errors ? setErrorRequest(true) : setProductos(responsePcts);
			}
			

			//const responsePres = await getPresentacion();
			//responsePres.errors ? setErrorRequest(true) : setPresentacion(responsePres);
			
			const responseEst = await getEstudioActivo();
			if(typeof responseEst != 'string'){
				responseEst.errors ? setErrorRequest(true) : setEstudio(responseEst);
			}
			

			const responseMsg15 = await getMsjQuinPdcs();
			responseMsg15.errors ? setMsgInfo("") : setMsgInfo(
				responseMsg15.map((value) => (
					//value.periodo
					<div><p>El grupo de los 15 esta compuesto por:</p><ul><li>Arroz grano entero 80% - 1 Kg</li><li>Frijoles negros o rojos primera calidad - 1 Kg</li><li>Atún trocitos de lomo en aceite - 100 g</li><li>Aceite de soya - 1 L</li><li>Manteca - 500 g</li><li>Sal regular - 500 g</li><li>Azúcar regular blanca - 1 Kg</li><li>Pastas alimenticias, tipo lengua o espagueti - 250 g</li><li>Harina de trigo - 1 Kg</li><li>Harina de maíz blanco - 1 Kg</li><li>Tortillas de maíz - 10 unidades</li><li>Café molido con azúcar - 250 g</li><li>Leche fresca corta duración semidescremada 2% grasa - 1 L</li><li>Leche en polvo semidescremada - 360 G</li><li>Leche en polvo entera - 500 g</li></ul></div>
				))  
			);

			setPcsMsg(["32"]);
		}

		fetchInitialData();
	}, []);

	useEffect(() => {
		//setLoading(true);  // here loading is true
		setCantones([]);

		var idEstudioActivo = "1";

		estudio.map((value) => (
			idEstudioActivo = value.elEstudio.idEstudio
		)) 
		async function fetchCantones() {
			const response = await getCantones(watchProvincia, idEstudioActivo);
			response.errors ? setErrorRequest(true) : setCantones(response);
		}
		if (watchProvincia) {
			fetchCantones();
		}
	}, [watchProvincia]);

	useEffect(() => {
		setDistritos([]);
		async function fetchDistritos() {
			const response = await getDistritos(watchCanton);
			response.errors ? setErrorRequest(true) : setDistritos(response);
		}
		if (watchCanton) {
			fetchDistritos();
		}
	}, [watchCanton]);

	useEffect(() => {
		setPresentacion([]);
		async function fetchPresntaciones() {
		/*	if (pcsMsg.includes(watchProducto)){
				setVisibleDialog(true);
			}*/
			const response = await getPresentaciones(watchProducto);
			response.errors ? setErrorRequest(true) : setPresentacion(response);
		}
		if (watchProducto) {
			fetchPresntaciones();
		}
	}, [watchProducto]);
	

	/*if (loading) return
    return (
        <Spinner animation="border" variant="primary" />
    );*/

	const onChange = () => {
		cambiarCaptchaValido(true);
	}

	const cambiaProvincia = () =>{
		setIsValidProv(true);
		setErrorValidMsgProv("");
		window.grecaptcha.reset();
	}

	const cambiaProducto = () =>{
		setIsValidProd(true);
		setErrorValidMsgProd("");
		window.grecaptcha.reset();
	}

	return (
		<div className="blockui-demo gris-fondo">
			<div className='cuerpo gris-fondo'>

			<BlockUI blocked={blockedDocument} fullScreen />
				
					<nav className='head-arriba navbar'>
						<a className='imagen-meic'>
							<img src={logo} alt="Logo MEIC" width="500" className='mostrarPequeño'/>
							
						</a>
						<div className=''>
							<h1 className='titulo'> Precios de Canasta Básica</h1>
						</div>
					</nav>
					<br/>
					<div className='container'>
							<Form.Label className='label-estudio' htmlFor="idProvincia">
							Precios basados en estudio realizado {
										estudio.map((value) => (
											value.periodo
										))  
									} 
							</Form.Label>
							<br/><br/>
							<Row className="mb-3">
								<Form.Group as={Col} className='col-12 col-md'>
									<Form.Label className='label-meic' htmlFor="idProvincia">Provincia</Form.Label>
									<Form.Label className='label-ast' >*</Form.Label>
									<Form.Select  name="idProvincia" ref={register()} onChange={cambiaProvincia}>
										<option value="">-- SELECCIONE --</option>=
										{provincia.map((value) => (
											<option value={value.idProvincia} key={value.idProvincia}>
												{value.nombre}
											</option>
										))}
									</Form.Select >
									{isValidProv 
										? <br/>
										: <Alert id='msjProv' variant="danger" onClose={() => setIsValidProv(true)} dismissible>
											{ errorValidMsgProv }
										</Alert>
									}
								</Form.Group>
								<Form.Group as={Col} className='col-12 col-md'>
									<Form.Label className='label-meic' htmlFor="idCanton">Canton</Form.Label>
									<Form.Select name="idCanton" ref={register()} required>
										<option value="">-- SELECCIONE --</option>
										{canton.map((value) => (
											<option value={value.cantonPK.idCanton} key={value.cantonPK.idCanton}>
												{value.nombre}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group as={Col} className='col-12 col-md'>
									<Form.Label className='label-meic' htmlFor="idProducto">Producto</Form.Label>
									<Form.Label className='label-ast' >*</Form.Label>
									<Form.Select name="idProducto" ref={register()} onChange={cambiaProducto}>
										<option value="">-- SELECCIONE --</option>
										{producto.map((value) => (
											<option value={value.idProducto} key={value.idProducto}>
												{value.nombre}
											</option>
										))}
									</Form.Select>
									{isValidProd 
											? <br/>
											: <Alert id='msjProv' variant="danger" onClose={() => setIsValidProd(true)} dismissible>
												{ errorValidMsgProd }
											</Alert>
										}
								</Form.Group>
								<Form.Group as={Col} className='col-12 col-md'>
									<Form.Label className='label-meic' htmlFor="idPresentacion">Presentación</Form.Label>
									<Form.Select name="idPresentacion" ref={register()}>
										<option value="-123">-- TODAS --</option>
										{presentacion.map((value) => (
											<option value={value.idPresentacion} key={value.idPresentacion}>
												{value.tamano + " " + value.unidad}
											</option>
										))}
									</Form.Select>
								</Form.Group>


							</Row>
							<ReCAPTCHA
								ref={captcha}
								sitekey="6LcqXbwiAAAAAMCq9hc4EZWNt1CJvb_PoYZf4Slp"
								onChange={onChange}
							/>
							{captchaValido === false && <div className='error-captcha'>Por favor acepte el captcha</div>}
							<br/><br/>
							<Button onClick={process}>
								Consultar
							</Button>
							<br/><br/>


							<div className="card">
								<DataTable showGridlines  value={precio} stripedRows  rowGroupMode="subheader" groupRowsBy="tamano"
									emptyMessage="No se encontraron resultados." rowGroupHeaderTemplate={headerTemplate}  responsiveLayout="stack">
									<Column  field="marca"  header="Marca"/>
									
									<Column field="comercio" header="Comercio"/>
									<Column field="provincia" header="Provincia"/>
									<Column field="canton" header="Cantón"/>
									<Column field="distrito" header="Distrito"/>
									<Column field="direccion" header="Dirección"/>
									<Column field="precio"  header="Precio" body={plantillaPrecio}/>
								</DataTable>		
							</div>

							<br/><br/>

							<br/><br/><br/><br/>
							
					</div>
					<div className='container-fluid fondo-azul'>
						<footer className='div-abajo fondo-azul'>
							<div className="row div-abajo" >
								<div className="col-12 col-md-6 text-center pt-4">
									<img src={logo_blanco} alt="Logo MEIC" width="700" className='img-fluid'/>
									
									<div className="col-12 col-xl-12 p-4">
										<a className="fa-lg fa-stack social-media-button" href="https://www.meic.go.cr/" target="_blank"  title="Sitio Web">
												<FontAwesomeIcon className='iconos' icon={faGlobe}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.facebook.com/MEICCR" target="_blank" title="Facebook">
												<FontAwesomeIcon className='iconos' icon={faFacebook}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.twitter.com/meic_crc" target="_blank" title="Twitter">
											<FontAwesomeIcon className='iconos' icon={faTwitter}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.youtube.com/user/MEICCR" target="_blank" title="YouTube">
											<FontAwesomeIcon className='iconos' icon={faYoutube}/>
										</a>
									</div>
									
								</div>
								<div className="col-12 col-md-6 centrar pt-4"  >
									<img src={logo_ice} alt="Logo KOLBI" width="158" className='img-fluid'/>
									<br/><br/>
									<div className="col-12 col-xl-12  ">
										<a className="fa-lg fa-stack social-media-button" href="https://www.kolbi.cr/" target="_blank"  title="Sitio Web">
											<FontAwesomeIcon className='iconos' icon={faGlobe}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.facebook.com/kolbicr" target="_blank" title="Facebook">
											<FontAwesomeIcon className='iconos' icon={faFacebook}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.instagram.com/kolbi_cr" target="_blank" title="Instagram">
											<FontAwesomeIcon className='iconos' icon={faInstagram}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.twitter.com/kolbi_cr" target="_blank" title="Twitter">
											<FontAwesomeIcon className='iconos' icon={faTwitter}/>
										</a>
										<a className="fa-lg fa-stack social-media-button" href="https://www.youtube.com/@kolbi_cr" target="_blank" title="YouTube">
											<FontAwesomeIcon className='iconos' icon={faYoutube}/>
										</a>
										
									</div>
								</div>
							</div>
							<div className='version'>13112023</div>
						</footer>
					</div>
					<Dialog header="Información" visible={visibleDialog} onHide={() => onHideDialog()}  breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}>
    					{msgInfo}
					</Dialog>
			</div>
		</div>
	);
}

export default App;
