import styled from 'styled-components';

export const SelectInput = styled.select`
	width: 180px;
	height: 51px;
	font-size: 14px;
	line-height: 16px;
	background: #f3f3f3;
	border-radius: 5px;
	padding: 15px;
	border: none;
	font-weight: bold;
	color: #656363;
`;

export const Label = styled.label`
	width: 150px;
	height: 51px;
	font-size: 14px;
	line-height: 16px;
	padding: 15px;
	border: none;
	font-weight: bold;
	color: #7DB439;
`;


export const Topnav  = styled.div`
	background-color: #F8F9FA;
	overflow: hidden;
	padding-left: 15px;
	padding-top: 15px;
	display: grid;
`

export const InputContainer = styled.div`
	@media (min-width: 700px) {
		flex-wrap: nowrap;
		border-style: inset;
		width: 100%;
		border-style: dotted;
		padding: 30px 0px 30px 0px;

		display: flex;

		justify-content: center;
	  }

	  @media (max-width: 700px) {
		flex-wrap: nowrap;
		border-style: inset;
		width: 100%;
		border-style: dotted;
		padding: 30px 0px 30px 0px;

		justify-content: center;
	  }
`;

export const InputWrapper = styled.div`
	padding: 0px 10px 0px 10px;
`;

export const CenterPanel = styled.div`
	margin: auto;
	width: 50%;
	padding: 10px;
`;

export const Button = styled.button`
	background-color: #7DB439;
	border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
	padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
`;

export const Table = styled.table`
    padding-top: 20px;
	margin-left: auto;
	margin-right: auto;
`;

export const TH = styled.th`
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #7DB439;
	color: white;
`;

export const TR = styled.tr`
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	background-color: #f2f2f2;;
	border-bottom: 1pt solid black;

	@media (max-width: 700px) {
		font-size: 12px;
	}
`;

