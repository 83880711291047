import axios from 'axios';

const url = axios.create({
	baseURL: process.env.REACT_APP_API_URL,

	auth: {
		username: process.env.REACT_APP_API_USR,
		password: process.env.REACT_APP_API_PSD
	  }
});



export const getProvincias = (body) => {
	let result = url
		.get('/Provincia')
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};

export const getCantones = (id, estudio) => {
	let result = url
	//console.log(estudio)
		.get(`/Provincia/${id}/CantonConPrecio/Estudio/${estudio}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};

export const getDistritos = (id) => {
	let result = url
		.get(`/Canton/${id}/Distrito/`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};

export const getProductos = (body) => {
	let result = url
		.get('/Producto/estado/1')
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};

export const getPresentaciones = (id) => {
	let result = url
		.get(`/Presentacion/Producto/${id}/Estado/1`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
}

export const getEstudioActivo = (body) => {
	let result = url
		.get('/Estudio/Estado/1')
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
}

export const getMsjQuinPdcs = (body) => {
	let result = url
		.get('/Estudio/Estado/1')
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};

export const getPrecios = (idProv, idCant, idDist, idPcto, idPresent, idEstudio, tknCpch) => {
	var path = 'Precios/Consulta?';
	if (idProv !== ''){
		path += 'idProvincia='+idProv+'&';
	}
	if (idCant !== ''){
		path += 'idCanton='+idCant+'&';
	}
	/*if (idDist !== ''){
		path += 'idDistrito='+idDist+'&';
	}*/
	if (idPcto !== ''){
		path += 'idProducto='+idPcto+'&';
	}
	if (idPresent !== ''){
		path += 'idPresentacion='+idPresent+'&';
	}
	if (idEstudio !== ''){
		path += 'idEstudio='+idEstudio+'&';
	}

	path += 'cptch='+tknCpch;

	let result = url
		.get(path)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;
};